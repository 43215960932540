/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/global.scss';


function openModal(imageSrc,modal) {
    const modalImage = document.getElementById('photo-grid-modal-img');
    modal.classList.add('dflex');
    modalImage.src = imageSrc;
}

function closeModal(modal) {
    modal.classList.remove('dflex');
}

document.addEventListener('DOMContentLoaded', function () {

    var navbar = document.getElementById("navbar");
    var sticky = navbar.offsetTop;

    window.onscroll = function() {
        if (window.scrollY >= sticky) {
          navbar.classList.add("sticky");
        }
        if (window.scrollY === 0) {
          navbar.classList.remove("sticky");
        }
    }

    var navbarMenuButton = document.getElementById("navbar-menu-button");
    var navbarMenu = document.getElementById("navbar-menu");
    if(navbarMenuButton){
        navbarMenuButton.addEventListener('click', function(){
            navbarMenu.classList.toggle("responsive");
        });
    }


       
    var modal = document.getElementById('photo-grid-modal');

    var gridImageByClass = document.getElementsByClassName('photo-grid--img');
    var gridImageArray = Array.from(gridImageByClass);
    
    gridImageArray.forEach(function(gridImage) {
       
        var imageSrcElement = gridImage.querySelector('img');
        var imageSrc = imageSrcElement.getAttribute('src');
        var bodyElement = document.body;

        if(gridImage && modal){
            modal.addEventListener('click', function() {
                if (bodyElement.classList.contains('scroll-disabled')) {
                    bodyElement.classList.remove('scroll-disabled');
                }
                closeModal(modal);
            });
            gridImage.addEventListener('click', function() {
                openModal(imageSrc,modal)
                bodyElement.classList.add('scroll-disabled');
            });
        } else {
            console.error('non element');
        }
    });
});
